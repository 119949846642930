import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { getLanguage, translations } from '../utils/language';

library.add(faSearch);

function Home() {
  const [trackingNumber, setTrackingNumber] = useState('');
  const lang = getLanguage();
  const t = translations[lang];

  const onSubmit = (e) => {
    e.preventDefault();
    if (trackingNumber !== '') {
      window.location = `/${trackingNumber}`;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen flex flex-col">
      <Header />

      <div className="flex-grow flex items-center justify-center px-4">
        <div className="w-full max-w-md">
          <form onSubmit={onSubmit} className="bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2" htmlFor="tracking-number">
                {t.trackingNumber}
              </label>
              <div className="flex">
                <input
                  className="shadow appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  id="tracking-number"
                  type="text"
                  placeholder={t.enterTrackingNumber}
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  <FontAwesomeIcon icon="search" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;