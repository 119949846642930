export const getLanguage = () => {
  const browserLang = navigator.language || navigator.userLanguage;
  return browserLang.startsWith('fr') ? 'fr' : 'en';
};

export const translations = {
  fr: {
    trackingTitle: 'Suivi de votre commande',
    loading: 'Chargement...',
    error: 'Une erreur s\'est produite',
    trackingNumber: 'Numéro de suivi',
    enterTrackingNumber: 'Entrez votre numéro de suivi',
    totalPrice: 'Prix Total',
    trackingHistory: 'Historique du Suivi'
  },
  en: {
    trackingTitle: 'Track your order',
    loading: 'Loading...',
    error: 'An error occurred',
    trackingNumber: 'Tracking number',
    enterTrackingNumber: 'Enter your tracking number',
    totalPrice: 'Total Price',
    trackingHistory: 'Tracking History'
  }
}; 