import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { getLanguage, translations } from '../utils/language';

import './Tracking.css'; 

function Tracking() {
  const idTracking = window.location.pathname.split('/')[1];
  const [trackingData, setTrackingData] = useState(null);
  const [error, setError] = useState(null);
  const [deliverySteps, setDeliverySteps] = useState([]);
  const lang = getLanguage();
  const t = translations[lang];

  useEffect(() => {
    if (!idTracking) return;
    fetch(`https://api.order-24.net/deliverySteps?trackingNumber=${idTracking}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Received data:', data);
        setTrackingData(data);
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error.message);
      });
  }, [idTracking]);

  useEffect(() => {
    if (trackingData && trackingData.deliverySteps) {
      const steps = trackingData.deliverySteps;
      const updatedSteps = steps.map(step => ({
        ...step,
        isGreen: true
      }));
      setDeliverySteps(updatedSteps);
    }
  }, [trackingData]);

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-900 min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow container mx-auto px-4 py-8 md:py-16">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl mb-8">
            {t.error}
          </h2>
          <p className="text-gray-700 dark:text-gray-300">{error}</p>
        </div>
        <Footer />
      </div>
    );
  }

  if (!trackingData) {
    return (
      <div className="bg-white dark:bg-gray-900 min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow container mx-auto px-4 py-8 md:py-16">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl mb-8">
            {t.loading}
          </h2>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen flex flex-col">
      <Header />

      <div className="flex-grow container mx-auto px-4 py-8 md:py-16">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl mb-8" style={{
          textAlign: 'center',
        }}>
          {t.trackingTitle} #{idTracking}
        </h2>

        <div className="flex flex-col lg:flex-row lg:gap-8">
          <div className="w-full lg:w-2/3 mb-8 lg:mb-0">
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 dark:divide-gray-700 dark:border-gray-700">
              {trackingData.items && Array.isArray(trackingData.items) && trackingData.items.map((item, index) => (
                <div key={index} className="space-y-4 p-6">
                  <div className="flex items-center justify-between gap-4">
                    <p className="min-w-0 flex-1 font-medium text-gray-900 dark:text-white">
                      {item.name}
                    </p>
                    <div className="flex items-center justify-end gap-4">
                      <p className="text-base font-normal text-gray-900 dark:text-white">x{item.quantity}</p>
                      <p className="text-lg font-bold leading-tight text-gray-900 dark:text-white">{item.price} €</p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="space-y-4 bg-gray-50 p-5 dark:bg-gray-800">
                <dl className="flex items-center justify-between gap-4 border-gray-200 dark:border-gray-700">
                  <dt className="text-xlfont-bold text-gray-900 dark:text-white">{t.totalPrice}</dt>
                  <dd className="text-xl font-bold text-gray-900 dark:text-white">
                    {trackingData.items && Array.isArray(trackingData.items) ? trackingData.items.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2) : '0'} €
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/3">
            <div className="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{t.trackingHistory}</h3>

              <ol className="relative ms-3 border-s border-gray-200 dark:border-gray-700">
                {deliverySteps.map((step, index) => (
                  <li key={index} className="mb-10 ms-6">
                    <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-green-500 ring-8 ring-white dark:bg-green-700 dark:ring-gray-800">
                      <svg className="h-4 w-4 text-white dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                      </svg>
                    </span>
                    <h4 className="mb-0.5 text-base font-semibold text-gray-900 dark:text-white">{step.date} - {step.location}</h4>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">{step.step}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default Tracking;
