import React from 'react';

function Footer() {
  return (
    <footer className="py-4 flex flex-col items-center justify-center text-gray-500 dark:text-gray-400">
      <div className="text-center">
        <p>© 2025 ORDER-24 LLC</p>
      </div>
    </footer>
  );
}

export default Footer;